import React from 'react';
import { Provider } from 'react-redux';
import store from './src/store';

import './src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Provider store={store}>{element}</Provider>
    </>
  );
};

export const onRouteUpdate = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });

  setTimeout(() => {
    document
      .querySelectorAll('script.y-rich-snippet-script')
      .forEach((script) => {
        script.remove();
      });
  }, 1000); // Delay ensures script is removed after injection
};
