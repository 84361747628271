import { CartItem, Customer } from '../../types';
import wooCommerce from '../../utils/woocommerce';
import axios from 'axios';

export const createWooCommerceCustomer = (userPayload: Partial<Customer>) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await wooCommerce.post('customers', userPayload);

      if (response.data.code === 'registration-error-email-exists') {
        return reject({
          msg: 'An account is already registered with your email address.',
        });
      } else {
        return resolve({
          msg: 'Customer created successfully.',
          data: response.data,
        });
      }
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};

export const getWooCommerceCustomer = (email: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await wooCommerce.get(`customers?email=${email}`);

      if (response.data?.length > 0) {
        return resolve({
          msg: 'User found',
          data: response.data,
        });
      } else {
        return reject({
          msg: 'User not found',
        });
      }
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};

export const updateUserCart = (
  userId: string | null,
  cartItems: CartItem[] | null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(cartItems, cartItems?.length, userId, '<<-- cartItems');
      if (!userId) {
        return reject({
          msg: 'User ID is required',
        });
      }
      const payload = {
        meta_data: [
          {
            key: 'cart_items',
            value: cartItems?.length > 0 ? cartItems : [],
          },
        ],
      };

      const response = await wooCommerce.put(`customers/${userId}`, payload);
      // console.log(response, "<<-- response");
      if (!cartItems || cartItems.length === 0) {
        localStorage.removeItem('cartData');
      }
      return resolve({
        msg: 'User updated successfully',
        data: response.data,
      });
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};

export const getUserById = (userId: string | null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        return reject({
          msg: 'User ID is required',
        });
      }
      const response = await wooCommerce.get(`customers/${userId}`);
      return resolve({
        msg: 'User found',
        data: response.data,
      });
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};

export const updateAccountDetails = async (userId: any, data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        return reject({
          msg: 'User ID is required',
        });
      }
      const response = await wooCommerce.put(`customers/${userId}`, data);

      return resolve({
        msg: 'User found',
        data: response.data,
      });
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};

export const tokenDeletion = async (userId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        return reject({
          msg: 'User ID is required',
        });
      }
      const TOKEN_DELETE_URL = process.env.GATSBY_TOKEN_DELETE_URL as string;
      const response = await axios.post(
        TOKEN_DELETE_URL + `?user_id=${userId}`
      );

      return resolve({
        msg: 'Token delete',
        data: response,
      });
    } catch (error: any) {
      return reject({
        msg: error.message,
      });
    }
  });
};
