exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-all-peptides-index-tsx": () => import("./../../../src/pages/all-peptides/index.tsx" /* webpackChunkName: "component---src-pages-all-peptides-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-category-index-tsx": () => import("./../../../src/pages/category/index.tsx" /* webpackChunkName: "component---src-pages-category-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-coupon-coupon-code-tsx": () => import("./../../../src/pages/coupon/[couponCode].tsx" /* webpackChunkName: "component---src-pages-coupon-coupon-code-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-my-account-index-tsx": () => import("./../../../src/pages/my-account/index.tsx" /* webpackChunkName: "component---src-pages-my-account-index-tsx" */),
  "component---src-pages-my-addresses-index-tsx": () => import("./../../../src/pages/my-addresses/index.tsx" /* webpackChunkName: "component---src-pages-my-addresses-index-tsx" */),
  "component---src-pages-my-orders-index-tsx": () => import("./../../../src/pages/my-orders/index.tsx" /* webpackChunkName: "component---src-pages-my-orders-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-sitemap-index-tsx": () => import("./../../../src/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-tsx" */),
  "component---src-pages-spartan-affiliate-index-tsx": () => import("./../../../src/pages/spartan-affiliate/index.tsx" /* webpackChunkName: "component---src-pages-spartan-affiliate-index-tsx" */),
  "component---src-pages-thankyou-index-tsx": () => import("./../../../src/pages/thankyou/index.tsx" /* webpackChunkName: "component---src-pages-thankyou-index-tsx" */),
  "component---src-pages-thankyou-order-token-tsx": () => import("./../../../src/pages/thankyou/[order_token].tsx" /* webpackChunkName: "component---src-pages-thankyou-order-token-tsx" */),
  "component---src-templates-blogs-blog-category-template-tsx": () => import("./../../../src/templates/blogs/blogCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-blogs-blog-category-template-tsx" */),
  "component---src-templates-blogs-blog-template-tsx": () => import("./../../../src/templates/blogs/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blogs-blog-template-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/CmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-product-list-tsx": () => import("./../../../src/templates/productList.tsx" /* webpackChunkName: "component---src-templates-product-list-tsx" */),
  "component---src-templates-products-product-template-tsx": () => import("./../../../src/templates/products/productTemplate.tsx" /* webpackChunkName: "component---src-templates-products-product-template-tsx" */)
}

